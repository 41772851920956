'use client';

import { Session } from 'next-auth';
import { ReactNode } from 'react';

import { useSiteDataClearance } from '../../common/api/hooks/useSiteDataClearance';
import { initI18n } from '../../common/i18n/client';
import { Configuration } from '../../configuration';
import { ConfigContextProvider } from '../../contexts/ConfigContext';
import { useDatadog } from '../../hooks/useDatadog';
import { useFeatureApiUrl } from '../../hooks/useFeatureApiUrl';
import { ThemeProvider } from '../templates/ThemeProvider';

initI18n();

export function Layout({ children, config }: { children: ReactNode; config: Configuration }) {
  return (
    <ConfigContextProvider config={config}>
      <LayoutContent>{children}</LayoutContent>
    </ConfigContextProvider>
  );
}

function LayoutContent({ children }: { children: ReactNode }) {
  const { isLoading: isApiUrlPending } = useFeatureApiUrl();
  const { isLoading: isDatadogPending } = useDatadog();
  const isPending = isDatadogPending || isApiUrlPending;
  useSiteDataClearance();

  return <ThemeProvider>{isPending ? null : children}</ThemeProvider>;
}
