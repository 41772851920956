import { useCallback } from 'react';

import { CompanyInfo, RegisterFormData } from '..';
import { AuthUser } from '../../session';
import { useApi } from './useApi';

export function useAuthApi() {
  const { get, post } = useApi('auth');

  const initPasswordReset = useCallback(
    ({ email }: { email: string }, options?: { isKeycloak?: boolean }) =>
      post(options?.isKeycloak ? 'forgot/keycloak' : 'forgot', { data: { email }, isPublic: true }),
    [post],
  );

  const login = useCallback(
    (data: { email: string; password: string }) => post<{ access_token: string }>('login', { data, isPublic: true }),
    [post],
  );

  const me = useCallback(() => get<AuthUser>('me'), [get]);

  const register = useCallback(
    (data: RegisterFormData & { companyUid?: string }) => post('register', { data, isPublic: true }),
    [post],
  );

  const resetPassword = useCallback(
    (data: { password: string; token: string }) => post('reset', { data, isPublic: true }),
    [post],
  );

  const siteDataClearance = useCallback(
    (data: { siteDataClearedAt?: Date }) =>
      post<{ isRequired: boolean }>('site-data-clearance', { data, isPublic: true }),
    [post],
  );

  const verify = useCallback(
    (companyUid: string) => get<CompanyInfo>('verify', { params: { companyUid }, isPublic: true }),
    [get],
  );

  return { initPasswordReset, login, me, register, resetPassword, siteDataClearance, verify };
}
